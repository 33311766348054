var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("img", {
          staticClass: "imgageproperty",
          attrs: { src: require("@/assets/img/errorlist.png"), alt: "save" }
        }),
        _vm._v(" " + _vm._s(_vm.$t("Error List")) + " ")
      ]),
      _c("br"),
      _c(
        "v-card",
        { staticStyle: { "border-radius": "10px" } },
        [
          _c("v-toolbar", {
            attrs: { flat: "" },
            scopedSlots: _vm._u([
              {
                key: "extension",
                fn: function() {
                  return [
                    _c(
                      "v-tabs",
                      {
                        staticStyle: { "margin-top": "-135px" },
                        attrs: { grow: "" },
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      [
                        _c("v-tabs-slider"),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text tab-divider",
                            staticStyle: { "border-top-left-radius": "10px" },
                            attrs: { href: "#tab-1st" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("ERROR LIST")))])]
                        ),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text tab-divider",
                            attrs: { href: "#tab-2nd" }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("ERROR LIST INCLUDE INFO")))
                            ])
                          ]
                        ),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text tab-divider",
                            staticStyle: { "border-top-right-radius": "10px" },
                            attrs: { href: "#tab-3rd" }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("ERROR LIST BY GW")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "v-tabs-items",
            {
              staticStyle: { padding: "40px" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.tab === "tab-1st"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-1st",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("error-list")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "tab-2nd"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-2nd",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("errorlist-info")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "tab-3rd"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-3rd",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("errorlist-gateway")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }