var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "searchArea", staticStyle: { "margin-top": "-64px" } },
        [
          _c("label", [_c("strong", [_vm._v(_vm._s(_vm.$t("Gateway")))])]),
          _c("v-select", {
            staticClass: "form-select ml-3",
            staticStyle: { width: "20%" },
            attrs: {
              placeholder: _vm.$t("Gateway"),
              items: _vm.gatewayList,
              "item-text": "name",
              "item-value": "id",
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            model: {
              value: _vm.searchByGw.gateway,
              callback: function($$v) {
                _vm.$set(_vm.searchByGw, "gateway", $$v)
              },
              expression: "searchByGw.gateway"
            }
          }),
          _c("label", { staticClass: "lastupdated" }, [
            _vm._v(_vm._s(_vm.$t("Last Updated Time")))
          ]),
          _c("label", [
            _c("span", { staticClass: "lastupdateddate" }, [
              _vm._v(_vm._s(_vm.lastupdatetime))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10",
          attrs: {
            headers: _vm.tableHeaders,
            items: _vm.tableItems,
            "item-class": _vm.getRowClass,
            "hide-default-footer": true,
            options: _vm.options,
            "server-items-length": _vm.totalgwpage
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("No data available")))])
          ])
        ],
        2
      ),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: {
                  disabled:
                    _vm.btnDisabledExport || _vm.btnDisabledExportCheckList,
                  text: ""
                },
                on: { click: _vm.exportErrorListGw }
              },
              [_vm._v(" " + _vm._s(_vm.$t("Export")) + " ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "pageInfo" }, [
          _vm._v(_vm._s(_vm.pageInfoText))
        ]),
        _c(
          "div",
          [
            _c("v-pagination", {
              staticClass: "pageAlign",
              attrs: {
                length: _vm.pageCount,
                "total-visible": _vm.totalgwpage,
                color: "#2f3b4c"
              },
              on: { input: _vm.paging },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ]),
      _c("a", { ref: "link", style: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }