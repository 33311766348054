<template>
  <div>
    <div class="searchArea" style="margin-top: -64px">
      <label
        ><strong>{{ $t("Dept.") }}</strong></label
      >
      <v-select
        style="width: 20%"
        v-model="searchBy.department"
        :placeholder="$t('Dept.')"
        :items="departmentList"
        class="form-select ml-3"
        item-text="name"
        item-value="level"
        outlined
        dense
        hide-details
        clearable
      >
      </v-select>
      <label class="lastupdated">{{ $t("Last Updated Time") }}</label>
      <label
        ><span class="lastupdateddate">{{ lastUpdatedTime }}</span></label
      >
    </div>
    <v-data-table
      :headers="errorListInclueHeaders"
      :items="errorListIncludeInfoList"
      :options.sync="options"
      :server-items-length="totalErrorListIncludePages"
      :hide-default-footer="true"
      class="tbl-type01 mt-10"
    >
      <template slot="no-data">
        <p>
          {{ $t("No data available") }}
        </p>
      </template>
    </v-data-table>
    <!-- button group -->
    <div class="table-options">
      <div>
        <v-btn
          :disabled="exportBtnDisabled"
          @click="exportTemplateList()"
          text
          class="btn ml-2"
          >{{ $t("Export") }}</v-btn
        >
      </div>
      <!-- paging -->
              <div class="pageInfo">{{ pageInfoText }}</div>

      <div>
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalErrorListIncludePages"
          color="#2f3b4c"
          @input="paging"
          class="pageAlign"
        ></v-pagination>
      </div>
    </div>
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'

export default {
  name: 'ErrorListIncludeInfo',
  components: {},
  data () {
    return {
      departmentList: [],
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      user: null,
      requestConfig: {},
      page: 1,
      totalPages: 0,
      totalVisiblePages: 5,
      options: {},
      searchBy: { department: '' },
      searchParam: {
        company: null,
        store: null,
        page: null,
        size: '10',
        sort: 'code,asc'
      },
      addDeleteDisabled: false,
      exportDisabled: false,
      errorListIncludeInfoList: [],
      lastUpdatedTime: null,
      totalErrorListIncludePages: 7,
      tabs: null,
      pageInfoText: null
    }
  },
  computed: {
    errorListInclueHeaders () {
      return [
        { text: this.$t('ITEM GR.'), sortable: true, value: 'itemGroup' },
        { text: this.$t('ITEM ID'), sortable: true, value: 'itemId' },
        { text: this.$t('DESCRIPTION'), sortable: true, value: 'description' },
        { text: this.$t('LABEL ID'), sortable: true, value: 'labelId' },
        { text: this.$t('NET PRICE'), sortable: true, value: 'price' },
        {
          text: this.$t('SECOND PLACEMENT'),
          sortable: true,
          value: 'amountLabel'
        },
        { text: this.$t('ERROR CODE'), sortable: false, value: 'errorCode' }
      ]
    },
    exportBtnDisabled () {
      return this.errorListIncludeInfoList.length < 1
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    })
  },
  watch: {
    options (val) {
      const columnInfo = {
        itemGroup: 'itemGroup',
        itemId: 'itemId',
        description: 'description',
        labelId: 'labelId',
        price: 'price',
        amountLabel: 'amountLabel',
        errorCode: 'errorCode'
      }
      let sortParam = null
      if (val.sortBy.length && val.sortDesc[0]) {
        sortParam = columnInfo[val.sortBy[0]] + ',DESC'
      } else if (val.sortBy.length && !val.sortDesc[0]) {
        sortParam = columnInfo[val.sortBy[0]] + ',ASC'
      }
      if (!commons.isNull(sortParam)) this.searchParam.sort = sortParam
      // this.page = 1
      this.getErrorListInfoList()
    },
    searchBy: {
      handler (newSearchBy) {
        if (newSearchBy) {
          this.page = 1
          this.getErrorListInfoList()
        }
        const ErrorListInclude = {
          searchBy: newSearchBy
        }
        setTempObj('errorlistinclude', ErrorListInclude)
      },
      deep: true
    },
    store: {
      handler () {
        this.getDepartmentList()
        this.getErrorListInfoList()
        this.page = 1
      }
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '5101').then(flag => {
      this.exportDisabled = flag
    })

    if (commons.notEmpty(this.$route.params)) {
      this.resetSearchBy()
      this.setRouterSearchCondition(this.$route)
    } else {
      this.initData()
    }
    EventBus.$emit('enableSelectedStores', true)

    const ErrorListSessionData = getTempObj('errorlistinclude')
    if (
      !commons.isNull(ErrorListSessionData) &&
      !commons.isNull(ErrorListSessionData.searchBy)
    ) {
      this.searchBy = ErrorListSessionData.searchBy
    }

    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getDepartmentList()
      this.getErrorListInfoList()
    }
  },
  methods: {
    resetSearchBy () {
      this.searchBy = {
        department: null
      }
    },
    setRouterSearchCondition (router) {
      if (commons.isAllInvalid([...Object.values(router.params)])) {
        return
      }
      if (!commons.isNull(router.params.department)) {
        this.searchBy.department = router.params.department
      }
    },
    initData () {
      this.searchParam = {
        ompany: null,
        store: null,
        page: null,
        size: '10',
        sort: 'code,asc'
      }
      this.page = 1
      this.searchBy = {
        department: null
      }
    },
    paging () {
      this.getErrorListInfoList()
    },
    exportTemplateList () {
      if (this.errorListIncludeInfoList.length < 1) {
        EventBus.$emit('messageAlert', this.$t('No data to export.'))
        return
      }
      const exportdata = {
        guiMap: {
          dept: this.$t('Dept.'),
          title_errorlist_info: this.$t('ERROR LIST INCLUDE INFO'),
          store_name: this.$t('STORE NAME'),
          item_group: this.$t('ITEM GR.'),
          item_id: this.$t('ITEM ID'),
          description: this.$t('DESCRIPTION'),
          tag_id: this.$t('LABEL ID'),
          price: this.$t('NET PRICE'),
          amount_tag: this.$t('SECOND PLACEMENT'),
          error_code: this.$t('ERROR CODE')
        },
        msgMap: {
          M0124: this.$t('date Edited'),
          M0125: this.$t('Signature Dept. Head'),
          M0126: this.$t('Error Code'),
          M0127: this.$t('ESL No'),
          M0128: this.$t('ESL no reception'),
          M0129: this.$t('ESL with an incorrect price'),
          M0130: this.$t('ESL false indication info level'),
          M0131: this.$t('Destroyed ESL'),
          M0132: this.$t('Other')
        }
      }

      this.$utils
        .callAxiosWithBody(
          codes.requests.exportErrorInculdeInfoList.method,
          codes.requests.exportErrorInculdeInfoList.url,
          exportdata,
          this.requestConfig
        )
        .then(res => {
          console.log(res)
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'ErrorListInclueInfoReport.pdf'
          link.click()
        })
        .catch(error => {
          console.debug(error)
        })
    },
    // params for export
    saveRequestConfig: function (config) {
      const lang = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      const params = {
        company: config.params.company, store: config.params.store, lang: lang
      }
      if (config.params.dept) {
        params.dept = config.params.dept
      }
      const requestConfig = {
        params: params,
        responseType: 'arraybuffer'
      }
      this.requestConfig = requestConfig
    },
    handleGetTempaltesResponse (res) {
      if (res.status === 204) {
        this.handleGetTempaltesError('No content')
        return
      }
      this.saveRequestConfig(res.config)
      this.errorListIncludeInfoList = res.data.errorList
      this.lastUpdatedTime = res.data.lastUpdatedTime
      this.pageInfoText = commons.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.totalPages = res.headers['x-totalpages'] * 1
      } else {
        this.totalPages = 1
      }
    },
    handleGetTempaltesError (error) {
      this.errorListIncludeInfoList = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 1
      console.debug(error)
    },
    buildParmas: function () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      const params = { company: this.user.company, store: this.store.code }
      let sort = null
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    getErrorListInfoList () {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
        EventBus.$emit('openSelectedStores')
      }
      const params = this.buildParmas()
      const config = { params: params }
      config.params.page = this.page - 1
      if (commons.isNull(config.params.store) || config.params.store === '') {
        this.handleGetTempaltesError('')
        return
      }
      if (this.searchBy.department) {
        config.params.dept = this.searchBy.department
      }
      this.$utils
        .callAxios(
          codes.requests.getErrorInculdeInfoList.method,
          codes.requests.getErrorInculdeInfoList.url,
          config
        )
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
          console.debug(error)
        })
    },
    getDepartmentList () {
      const params = { company: this.user.company, store: this.store.code }
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.getDepartmentList.method,
          codes.requests.getDepartmentList.url,
          config
        )
        .then(res => {
          this.departmentList = res.data.deptList
        })
        .catch(error => {
          this.departmentList = []
          console.debug(error)
        })
    }
  }
}
</script>
<style scoped>
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 18% !important;
  text-align: left;
}
.lastupdated {
  position: absolute;
  right: 0;
  margin-top: 25px;
  font-size: 14px;
}
.lastupdateddate {
  position: absolute;
  right: 0;
  font-size: 14px;
}
.v-application .mt-10 {
  margin-top: 24px !important;
}
.pageAlign{
  float:right;

}
</style>
