<template>
  <div>
    <h2 class="page-title-bar">
      <img class="imgageproperty" src="@/assets/img/errorlist.png" alt="save" />
      {{ $t("Error List") }}
    </h2>
    <br />
    <v-card style="border-radius: 10px">
      <v-toolbar flat>
        <template v-slot:extension>
          <v-tabs v-model="tab" grow style="margin-top: -135px">
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              href="#tab-1st"
              class="primary--text tab-divider"
              style="border-top-left-radius: 10px"
            >
              <span>{{ $t("ERROR LIST") }}</span>
            </v-tab>
            <v-tab href="#tab-2nd" class="primary--text tab-divider">
              <span>{{ $t("ERROR LIST INCLUDE INFO") }}</span>
            </v-tab>
            <v-tab
              href="#tab-3rd"
              class="primary--text tab-divider"
              style="border-top-right-radius: 10px"
            >
              <span>{{ $t("ERROR LIST BY GW") }}</span>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab" style="padding: 40px">
        <v-tab-item  :value="'tab-1st'" :transition="false" :reverse-transition="false" v-if="tab === 'tab-1st'">
          <v-card flat>
            <error-list></error-list>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-2nd'" :transition="false" :reverse-transition="false" v-if="tab === 'tab-2nd'">
          <v-card flat>
            <errorlist-info></errorlist-info>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-3rd'" :transition="false" :reverse-transition="false" v-if="tab === 'tab-3rd'">
          <v-card flat>
            <errorlist-gateway></errorlist-gateway>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import ErrorListpage from './pages/ErrorListpage.vue'
import ErrorListIncludeInfo from './pages/ErrorListIncludeInfo'
import ErrorListByGateway from './pages/ErrorListByGateway'
export default {
  //   name: 'Error List',
  components: {
    'error-list': ErrorListpage,
    'errorlist-info': ErrorListIncludeInfo,
    'errorlist-gateway': ErrorListByGateway
  },
  data () {
    return {
      tab: null
    }
  }
}
</script>
<style scoped>
.imgageproperty {
  margin-right: 14px;
  height: 24px;
}
.tab-divider {
  border-right: 3px solid #ccd2d7;
}
.tab-divider:last-child  {
  border-right: 0
}
</style>
