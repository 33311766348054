<template>
  <div>
    <div class="searchArea" style="margin-top: -64px">
      <label><strong>{{$t('Gateway')}}</strong></label>
      <v-select
        style="width: 20%"
        v-model="searchByGw.gateway"
        :placeholder="$t('Gateway')"
        :items="gatewayList"
        class="form-select ml-3"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        clearable>
    </v-select>
      <label class="lastupdated">{{$t('Last Updated Time')}}</label>
      <label><span class="lastupdateddate">{{lastupdatetime}}</span></label>
    </div>
    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="tableItems"
      :item-class="getRowClass"
      :hide-default-footer="true"
      :options.sync="options"

      :server-items-length="totalgwpage"
      class="tbl-type01 mt-10">
      <template slot="no-data">
        <p>{{ $t("No data available") }}</p>
      </template>
    </v-data-table>

    <div class="table-options">
      <div>
        <v-btn
          :disabled="btnDisabledExport || btnDisabledExportCheckList"
          @click="exportErrorListGw"
          class="btn ml-2"
          text>
          {{ $t("Export") }}
        </v-btn>
      </div>
              <div class="pageInfo">{{ pageInfoText }}</div>

      <div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="pageCount"
          :total-visible="totalgwpage"
          color="#2f3b4c"
          class="pageAlign"
          >
          </v-pagination>
      </div>
    </div>
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
// import { exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'

export default {
  //   name: 'Error List',

  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      btnDisabledExport: null,
      lastupdatetime: null,
      page: 1,
      user: null,
      pageCount: 0,
      pageInfoText: null,
      tableItems: [],
      selected: [],
      options: {},
      totalgwpage: 7,
      ROW_CLASS: 'row-class',
      searchByGw: { gateway: '' },
      gatewayList: [],
      currentLanguage: '',
      requestConfig: {},
      searchParam: {
        company: null,
        store: null,
        page: null,
        size: '10',
        sort: ''
      }

    }
  },
  watch: {
    options: function (val) {
      let sortParam = null
      if (val.sortBy.length && val.sortDesc[0]) {
        sortParam += (val.sortBy[0] + ',DESC')
      } else if (val.sortBy.length && !val.sortDesc[0]) {
        sortParam += (val.sortBy[0] + ',ASC')
      }
      if (!commons.isNull(sortParam)) this.searchParam.sort = sortParam
      this.getErrorListGw()
    },
    searchByGw: {
      handler (newSearchBy) {
        if (newSearchBy) {
          this.page = 1
          this.getErrorListGw()
        }
        const ErrorListgw = {
          searchByGw: newSearchBy
        }
        setTempObj('errorlistGW', ErrorListgw)
      },
      deep: true
    },
    store: {
      handler () {
        this.getErrorListGw()
        this.getGatewayList()
        this.page = 1
      }
    }
  },

  computed: {
    tableHeaders () {
      return [
        { text: this.$t('ITEM GR.'), sortable: true, value: 'itemGroup' },
        { text: this.$t('ITEM ID'), sortable: true, value: 'itemId' },
        { text: this.$t('DESCRIPTION'), sortable: true, value: 'description' },
        { text: this.$t('LABEL ID'), sortable: true, value: 'labelId' },
        { text: this.$t('NET PRICE'), sortable: true, value: 'price' },
        { text: this.$t('SECOND PLACEMENT'), sortable: true, value: 'amountLabel' },
        { text: this.$t('ERROR CODE'), sortable: false, value: '' }
      ]
    },
    btnDisabledExportCheckList () {
      var flag = true
      if (this.tableItems.length) flag = false
      return flag
    },

    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    })
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    EventBus.$emit('enableSelectedStores', true)
    const ErrorListSessionData = getTempObj('errorlistGW')
    if (
      !commons.isNull(ErrorListSessionData) &&
      !commons.isNull(ErrorListSessionData.searchByGw)
    ) {
      this.searchByGw = ErrorListSessionData.searchByGw
    }

    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getErrorListGw()
      this.getGatewayList()
    }
  },

  methods: {
    paging () {
      this.getErrorListGw()
    },
    // params for export

    // export file
    exportErrorListGw () {
      if (this.tableItems.length < 1) {
        EventBus.$emit('messageAlert', this.$t('No data to export.'))
      }
      // const lang = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      const setvalue = {
        guiMap: {
          gw: this.$t('Gateway'),
          store_name: this.$t('STORE NAME'),
          title_errorlist_gw: this.$t('ERROR LIST BY GW'),
          item_group: this.$t('ITEM GR.'),
          item_id: this.$t('ITEM ID'),
          description: this.$t('DESCRIPTION'),
          tag_id: this.$t('LABEL ID'),
          price: this.$t('NET PRICE'),
          amount_tag: this.$t('SECOND PLACEMENT'),
          error_code: this.$t('ERROR CODE')

        },
        msgMap: {
          M0124: this.$t('date Edited'),
          M0125: this.$t('Signature Dept. Head'),
          M0126: this.$t('Error Code'),
          M0127: this.$t('ESL No'),
          M0128: this.$t('ESL no reception'),
          M0129: this.$t('ESL with an incorrect price'),
          M0130: this.$t('ESL false indication info level'),
          M0131: this.$t('Destroyed ESL'),
          M0132: this.$t('Other')
        }
      }
      const params = { company: this.user.company, store: this.store.code, lang: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'] }
      const config = { params: params }
      if (this.searchByGw.gateway) {
        config.params.gw = this.searchByGw.gateway
      }
      config.responseType = 'arraybuffer'
      this.$utils
        .callAxiosWithBody(
          codes.requests.getErrorListGWexport.method,
          codes.requests.getErrorListGWexport.url,
          setvalue,
          config

        )
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'ErrorListByGW.pdf'
          link.click()
        })

      // exportFiles(this.requestConfig, this.$refs.link, 'ErrorListByGW.xlsx')
    },
    handleGeterrorlistGWResponse (res) {
      if (res.status === 204) {
        this.handleGeterrorlistGWError('No content')
        return
      }

      // this.saveRequestConfig(res.config)
      this.tableItems = res.data.errorListGw
      this.lastupdatetime = res.data.lastUpdatedTime

      this.pageInfoText = commons.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.pageCount = res.headers['x-totalpages'] * 1
      } else {
        this.pageCount = 1
      }
      // this.page = res.headers['x-number'] * 1 + 1
    },
    handleGeterrorlistGWError (error) {
      this.tableItems = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.pageCount = 1
      this.selected = []
      console.debug(error)
    },
    // params for load initial data
    buildParmas: function () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      const params = { company: this.user.company, store: this.store.code }
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    getRowClass () {
      return codes.ROW_CLASS
    },

    // get inital load on table
    getErrorListGw () {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
        EventBus.$emit('openSelectedStores')
      }
      const params = this.buildParmas()
      if (this.page !== null) params.page = this.page - 1
      const config = { params: params }
      if (commons.isNull(config.params.store) || config.params.store === '') {
        this.handleGeterrorlistGWError('')
        return
      }

      if (this.searchByGw.gateway) {
        config.params.gw = this.searchByGw.gateway
      }
      this.$utils.callAxios(codes.requests.getErrorListGW.method, codes.requests.getErrorListGW.url, config).then(res => {
        this.handleGeterrorlistGWResponse(res)
      })
        .catch(error => {
          this.handleGeterrorlistGWError(error)

          console.debug(error)
        })
    },
    // load data for select textbox
    getGatewayList () {
      const params = { company: this.user.company, store: this.store.code }
      const config = { params: params }
      this.$utils
        .callAxios(codes.requests.getGWlist.method, codes.requests.getGWlist.url, config)
        .then(res => {
          this.gatewayList = res.data.gwList
        })
        .catch(error => {
          this.gatewayList = []
          console.debug(error)
        })
    }
  }
}
</script>

<style scoped>
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 18% !important;
  text-align: left;
}
.lastupdated {
  position: absolute;
  right: 0;
  margin-top: 25px;
  font-size: 14px;
}
.lastupdateddate {
  position: absolute;
  right: 0;
  font-size: 14px;
}
.v-application .mt-10 {
  margin-top: 24px !important;
}
.pageAlign{
  float:right;

}
</style>
